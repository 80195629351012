import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  Container, VpCard, Loader
} from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  710: { items: 3 },
  920: { items: 4 },
};

const FeaturedVps = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [vpsLoading, setVpsLoading] = useState(true);
  const [featuredVps, setFeaturedVps] = useState([]);

  const slidePrev = () => {
    const nextIndex = activeIndex - 1;
    if (nextIndex < 0) {
      return false;
    }
    setActiveIndex(activeIndex - 1);
    return true;
  };
  const slideNext = () => {
    const nextIndex = activeIndex + 1;
    if (nextIndex > setFeaturedVps.length) {
      return false;
    }
    setActiveIndex(activeIndex + 1);
    return true;
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  useEffect(() => {
    const endpointPath = getPath('/api/v1/user-profiles-featured');
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        setFeaturedVps(data || []);
        setVpsLoading(false);
      })
      .catch(() => {
        setVpsLoading(false);
      });
  }, []);

  return (
    <Container isSmall background="#EBF5FB">
      <div className="FeaturedVps">
        <div className="header">Become a Venture Partner</div>
        <div className="desc">And help investors work successfully with entrepreneurs</div>
        <div className="list-title">Featured VPs</div>
        <div className="list">
          {vpsLoading ? (
            <div className="LoaderContainer">
              <Loader />
            </div>
          ) : (
            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              controlsStrategy="alternate"
              items={featuredVps.map(vp => <VpCard key={vp.user_id} vp={vp} />)}
              activeIndex={activeIndex}
              responsive={responsive}
              onSlideChanged={syncActiveIndex}
            />
          )}
        </div>
        {!featuredVps.length ? (
          <div className="LoaderContainer">
            No featured vp's added
          </div>
        ) : null}
        <div className="actions">
          <div />
          <div className="b-refs-buttons">
            <button className="gallery-control-btn" onClick={slidePrev} disabled={vpsLoading}>
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z" fill="#191919" />
              </svg>
            </button>
            <button className="gallery-control-btn" onClick={slideNext} disabled={vpsLoading}>
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z" fill="#191919" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
};

export default FeaturedVps;
