import React from 'react';
import { Container } from '@components';
import './styles.scss';

const About = () => (
  <Container background="#fff">
    <div className="About">
      <h2 className="header">Our values</h2>
      <div className="content">
        <div className="item">
          <h3 className="label">Openness</h3>
          <div className="value">Kobo Funds values disruption and welcome unconventional ideas.</div>
        </div>
        <div className="item">
          <h3 className="label">Transparency</h3>
          <div className="value">Our community is careful to avoid conflicts of interest or inequitable information flow which gives rise to unfair advantage to the few to the detriment of the many.</div>
        </div>
        <div className="item">
          <h3 className="label">Professionalism</h3>
          <div className="value">Our community value talent and hard work as the main drivers of disruption.</div>
        </div>
        <div className="item">
          <h3 className="label">Entrepreneurship</h3>
          <div className="value">We believe in value creation by visionary leaders working for long-term growth, not through financial shortcuts.</div>
        </div>
        <div className="item">
          <h3 className="label">Philanthropy</h3>
          <div className="value">Our community encourages entrepreneurs and investors to share part of the wealth generated and provide them with a direct path to support select organisations.</div>
        </div>
      </div>
    </div>
  </Container>
);

export default About;
