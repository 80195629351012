import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Button, ProgressBar, JoinModal } from '@components';
import noImagePlaceholder from '../../assets/placeholder-image.png';
import { lockTheBody, unlockTheBody, parseObject } from '../helpers';
import config from '../config';
import './styles.scss';

ReactModal.setAppElement('#___gatsby');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.75)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '800px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '24px 28px',
    marginTop: '20px',
  },
};

const OfferingDetails = ({
  isOpen,
  closeCb,
  offering,
}) => {
  const [displayModal, setModalDisplay] = useState(false);
  const name = offering?.offering.name || '';
  const description = offering?.offering.description || '';
  const offering_picture = offering?.offering.logo || '';
  const amount_raised = Number(offering?.offering.amount_raised) || 0;
  const target = Number(offering?.offering.target) || 0;
  const backgroundImg = offering_picture || noImagePlaceholder;
  const percentage = (amount_raised / target) * 100;
  const bio = offering?.offering?.bio;
  const offeringSummary = parseObject(bio || '');

  const organization = offering?.business_entity_owners?.[0];
  const organizationName = organization?.name;

  useEffect(() => {
    lockTheBody();
    return () => unlockTheBody();
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="OfferingDetailsModal"
    >
      <div className="OfferingDetails">
        <div className="OfferingDetails-name">{name}</div>
        <div className="Organization">{organizationName}</div>
        <div className="OfferingDetails-banner">
          {backgroundImg ? (
            <img src={backgroundImg} className="BannerImg" alt="offering banner" />
          ) : (
            <div className="BannerImgDefault"></div>
          )}
        </div>
        <ProgressBar percentage={percentage} height="10px" />
        <div className="FundingContainer">
          <div className="AmountRaised">{`${amount_raised.toLocaleString()} Funded`}</div>
          <div className="AmountSeparator">/</div>
          <div className="AmountGoal">{`${target.toLocaleString()} Goal`}</div>
        </div>
        <div className="Section">
          <div className="title">Description</div>
          <div className="text">{description}</div>
        </div>
        <div className="Section">
          <div className="title">Company's purpose</div>
          <div className="text">{offeringSummary?.purpose || ''}</div>
        </div>
        <div className="Section">
          <div className="title">Market analysis</div>
          <div className="text">{offeringSummary?.market || ''}</div>
        </div>
        <div className="Section">
          <div className="title">Business model</div>
          <div className="text">{offeringSummary?.business_model || ''}</div>
        </div>
        <div className="Section">
          <div className="title">Offering</div>
          <div className="text">{offeringSummary?.offering || ''}</div>
        </div>
        <div className="close-btn">
          <button onClick={closeCb}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" className="IconClose"><path fillRule="evenodd" clipRule="evenodd" d="M0.390524 0.390524C0.911223 -0.130175 1.75544 -0.130175 2.27614 0.390524L8 6.11438L13.7239 0.390524C14.2446 -0.130175 15.0888 -0.130175 15.6095 0.390524C16.1302 0.911223 16.1302 1.75544 15.6095 2.27614L9.88562 8L15.6095 13.7239C16.1302 14.2446 16.1302 15.0888 15.6095 15.6095C15.0888 16.1302 14.2446 16.1302 13.7239 15.6095L8 9.88562L2.27614 15.6095C1.75544 16.1302 0.911223 16.1302 0.390524 15.6095C-0.130175 15.0888 -0.130175 14.2446 0.390524 13.7239L6.11438 8L0.390524 2.27614C-0.130175 1.75544 -0.130175 0.911223 0.390524 0.390524Z" fill="#A19E95"></path></svg>
          </button>
        </div>
        <div className="actions-container">
          <Button onClick={() => setModalDisplay(true)}>
            Join
          </Button>
          <div className="login">
            <a href={config.client_app_url} target="_blank" rel="noreferrer noopener">
              Already have an account? Log in here
            </a>
          </div>
        </div>
      </div>
      {displayModal && (
        <JoinModal
          isOpen={displayModal}
          closeCb={() => setModalDisplay(false)}
        />
      )}
    </ReactModal>
  );
};

OfferingDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
};

export default OfferingDetails;
