import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'
import {
  Container, OfferingCard, Loader
} from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const breakpointColumnsObj = {
  default: 3,
  920: 2,
  600: 1
};

const AllDeals = () => {
  const [offeringsLoading, setOfferingsLoading] = useState(true);
  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    const endpointPath = getPath('/api/v1/offerings?page=1&per_page=100');
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        const list = data?.records;
        setOfferings(list || []);
        setOfferingsLoading(false);
      })
      .catch(() => {
        setOfferingsLoading(false);
      });
  }, []);

  return (
    <Container isSmall background="#fff">
      <div className="AllDeals">
        <div className="list">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {offeringsLoading ? (
              <div className="LoaderOrganization"><Loader /></div>
            ) : offerings.map(of => <OfferingCard key={of?.offering?.id} offering={of} />)}
          </Masonry>
        </div>
      </div>
    </Container>
  );
}

export default AllDeals;
