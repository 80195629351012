import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, OfferingDetails } from '@components';
import noImagePlaceholder from '../../assets/placeholder-image.png';
import './styles.scss';

const OfferingCard = ({ offering }) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const name = offering?.offering.name || '';
  const description = offering?.offering.description || '';
  const offering_picture = offering?.offering.logo || '';
  const amount_raised = Number(offering?.offering.amount_raised) || 0;
  const target = Number(offering?.offering.target) || 0;
  const backgroundImg = offering_picture || noImagePlaceholder;
  const percentage = (amount_raised / target) * 100;

  const organization = offering?.business_entity_owners?.[0];
  const organizationName = organization?.name;

  return (
    <>
      <button className="OfferingCard" type="button" onClick={() => setDetailsOpen(true)}>
        <div className="Banner">
          {backgroundImg ? (
            <img src={backgroundImg} className="BannerImg" alt="offering banner" />
          ) : (
            <div className="BannerImgDefault"></div>
          )}
        </div>
        <div className="CardInner">
          <div className="Name">{name}</div>
          <div className="Organization">{organizationName}</div>
          <div className="Description">{description}</div>
          <ProgressBar percentage={percentage} />
          <div className="FundingContainer">
            <div className="AmountRaised">{`${amount_raised.toLocaleString()} Funded`}</div>
            <div className="AmountSeparator">/</div>
            <div className="AmountGoal">{`${target.toLocaleString()} Goal`}</div>
          </div>
        </div>
      </button>
      {isDetailsOpen && (
        <OfferingDetails
          isOpen={isDetailsOpen}
          closeCb={() => setDetailsOpen(false)}
          offering={offering}
        />
      )}
    </>
  );
};

OfferingCard.propTypes = {
  offering: PropTypes.object.isRequired,
};

export default OfferingCard;
