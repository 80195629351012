export const getPath = url => {
  const hostname = window?.location?.hostname;
  const isDevelopment = hostname === 'localhost'
  if (isDevelopment) {
    const devURL = `http://localhost:3055${url}`;
    return devURL;
  }
  return `https://admin.dev.factoro.com${url}`;
};

export const getJsonResponse = response => {
  return response.json()
}

// INSERT INTO investing.offering_tags(offering_id, tag_id)
// VALUES(11, 3);

//     INSERT INTO on_boarding.business_entity_tags(business_entity_id, tag_id)
// VALUES(11, 3);

// INSERT INTO on_boarding.user_tags(user_id, tag_id)
// VALUES(11, 3);

// INSERT INTO sharing.api_keys (id, expires_at)
//     VALUES ('8a1bbc2b-6970-458f-8363-ff6e53c5b53c', '2022-03-01');
