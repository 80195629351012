import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'
import { Container, VpCard, Loader } from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

// const teamData = [
//   { full_name: 'Alessandro Tosi', title: 'Founder & CEO', profile_picture: 'https://images.ctfassets.net/oc5vcml9yoyc/6xin0KHQsw0oy8M4s4EAaa/d0bcfe03216dd31edeec95ed68f79d20/alessandro.jpg' },
//   { full_name: 'Davide Grasso', title: 'Founder & Board Advisor', profile_picture: 'https://images.ctfassets.net/oc5vcml9yoyc/2KIj7glp5K04iMIC822YQE/b070e79834a67b45291b1eb2e62a08db/davidegrasso.jpg' },
//   { full_name: 'Maurizio de Martino', title: 'Partner', profile_picture: 'https://images.ctfassets.net/oc5vcml9yoyc/3YON2k9rMkMAmW42eC828w/edf14572f87fda21fc1cb1b75232b591/de_martino.png' },
//   { full_name: 'Filippo Sabatini', title: 'Partner', profile_picture: 'https://images.ctfassets.net/oc5vcml9yoyc/3Ek67m8BYQwUYKCCqAE2OE/4f397d5d6606ff644e910439f3c1bbbe/sabatini.png' },
//   { full_name: 'Rod Bailey', title: 'Director, Executive Sourcing', profile_picture: 'https://images.ctfassets.net/oc5vcml9yoyc/2n3m8glGEocym2U6GSq2UK/7fdb77cf1dbd4d0cff01da3e2c936717/bailey.png' },
// ];

const Team = () => {
  const [isLoading, setLoading] = useState(true);
  /* eslint-disable no-unused-vars */
  const [entity, setEntity] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const endpointPath = getPath('/api/v1/platform-owners');
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        const businessEntity = data[0]?.business_entity;
        const members = data[0]?.members;
        setEntity(businessEntity);
        setTeamMembers(members || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container isSmall background="#EBF5FB">
      <div className="Team">
        <div className="header">Founders & Management Team</div>
        {/* <div className="desc">And help investors work successfully with entrepreneurs</div>
        <div className="list-title">The management team & founders</div> */}
        <div className="list">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {isLoading ? (
              <div className="LoaderOrganization"><Loader /></div>
            ) : teamMembers.map(vp => <VpCard key={vp?.profile?.user_id} vp={vp?.profile} />)}
          </Masonry>
        </div>
      </div>
    </Container>
  );
};

export default Team;
